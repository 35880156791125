<template>
  <v-dialog v-model="isCancelPromotionModalVisible" max-width="550" persistent>
    <template>
      <v-card>
        <v-toolbar>
          <div
            class="d-flex align-center justify-space-between"
            style="width: 100%"
          >
            <h3 class="font-weight-regular text-uppercase">Cancel Promotion</h3>
            <v-spacer></v-spacer>
            <div>
              <v-icon @click="toggleCancelPromotionModal">mdi-close</v-icon>
            </div>
          </div>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="is_valid">
            <p>
              Are you sure you want to cancel "{{ promotion?.name }}" ?<br />
              Please select your reason for cancellation
            </p>
            <v-radio-group v-model="radioGroup">
              <v-radio value="incorrect">
                <template v-slot:label>
                  <span class="black--text darken-3">Incorrect Promotion</span>
                </template>
              </v-radio>
              <v-radio value="other">
                <template v-slot:label>
                  <span class="black--text darken-3">Other</span>
                </template>
              </v-radio>
            </v-radio-group>
            <v-textarea
              outlined
              label="Reason for cancellation"
              rows="1"
              v-model="reason"
              :disabled="radioGroup !== 'other'"
              :rules="[
                (v) => radioGroup !== 'other' || !!v || 'Reason required',
              ]"
            >
            </v-textarea>
            <div class="d-flex justify-end mx-0">
              <div>
                <v-btn text class="me-2" @click="toggleCancelPromotionModal"
                  >Close</v-btn
                >
                <v-btn color="error" class="me-0" @click="cancelPromotion"
                  >Cancel Promotion</v-btn
                >
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import promotionsService from "@/store/services/promotions-service";
import { Promotion } from "../promotion.class";
export default {
  props: {
    isCancelPromotionModalVisible: Boolean,
    toggleCancelPromotionModal: Function,
    promotion: Promotion,
    reload: Function,
  },
  computed: {
    console: () => console,
  },
  data() {
    return {
      is_valid: false,
      radioGroup: null,
      reason: null,
    };
  },
  watch: {
    endDate() {
      this.$refs.form.validate();
    },
  },
  methods: {
    cancelPromotion() {
      if (this.$refs.form.validate()) {
        promotionsService
          .cancel(this.promotion.id, {
            reason:
              this.radioGroup === "incorrect"
                ? "Incorrect Promotion"
                : this.reason,
          })
          .then(() => {
            this.reload();
            this.toggleCancelPromotionModal();
          });
      }
    },
  },
};
</script>